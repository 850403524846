var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.teams,"headers":[
      { text: 'Verein', value: 'club.name', sortable: false },
      { text: 'Turner', value: 'athletes', sortable: false },
      { text: 'löschen', value: 'deleted', sortable: false },
      { text: 'Gruppe', value: 'group', sortable: false, align: 'center' },
      { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' } ].concat( _vm.disc.map(function (d) { return ({ text: d.shortName, value: d._id, sortable: false }); })
    ),"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',{class:{deleted: item.deleted}},[_c('td',[_vm._v(_vm._s(item.club.name))]),_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._l((item.athletes),function(a,i){return [(i > 0)?_c('br',{key:i}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("person")(a))+" ")]})],2),_c('td',[(item.deleted)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.del(item)}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1):_vm._e()],1),_c('td',{staticStyle:{"text-align":"center"}},[_c('base-edit-dialog',{attrs:{"value":item.group,"id":("" + (item.teamId)),"label":"Gruppe"},on:{"input":function (ref) {
        var value = ref.value;
        var id = ref.id;

        return _vm.setgroup(parseInt(id), parseInt(value));
    }}})],1),_c('td',{staticStyle:{"text-align":"center"}},[_c('base-edit-dialog',{attrs:{"value":item.order,"id":("" + (item.teamId)),"label":"Reihenfolge"},on:{"input":function (ref) {
        var value = ref.value;
        var id = ref.id;

        return _vm.setorder(parseInt(id), parseInt(value));
    }}})],1),_vm._l((_vm.disc),function(d){return _c('td',{key:d._id},[(item._startdiscipline === d._id)?_c('v-icon',[_vm._v("far fa-check")]):_c('v-btn',{attrs:{"text":"","fab":"","x-small":""},on:{"click":function($event){return _vm.setstartdiscipline(item.teamId, d._id)}}},[_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("far fa-xmark")])],1)],1)})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }